<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>标签管理</el-breadcrumb-item>
        <el-breadcrumb-item>标签列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div></div>
      <div class="yt-header-toolbar">
        <el-input v-model="keyword" placeholder="通过标签名称搜索" class="yt-search" @keydown.enter.native="reSearchTag">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="reSearchTag" />
        </el-input>
        <el-button type="primary" size="small" @click="toCreate">
          <div>
            <i class="el-icon-circle-plus-outline" />
            <p>新建标签</p>
          </div>
        </el-button>
        <el-button size="small" @click="toDelete(false)">
          <div>
            <YTIcon :href="'#icon-shanchu'" />
            <p>删除标签</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="yt-container">
      <div class="yt-content">
        <div class="filter-container">
          <span style="margin-left: 10px" class="label"> 标签类型：</span>
          <span style="margin-left: 10px" class="item default-item" :class="{ 'active-item': typeId === '' }" @click="handleClickType('')">全部</span>
          <div v-for="(value, index) in type" :key="index">
            <span class="item default-item" :class="{ 'active-item': typeId === value.typeId }" @click="handleClickType(value.typeId)">
              {{ value.typeName }}
            </span>
          </div>
        </div>
        <div class="line"></div>
        <el-table
          :data="tagList"
          class="yt-table"
          height="calc(100% - 87px)"
          style="margin-top: 8px"
          :v-loading="tagLoading"
          @select="selectedLabelManagement"
          @select-all="selectedLabelManagement"
        >
          <el-table-column type="selection" width="31" />
          <el-table-column label="标签名称" prop="tagName" show-overflow-tooltip />
          <el-table-column label="创建时间" show-overflow-tooltip>
            <template slot-scope="scope">{{ $formatTime(scope.row.createdTime, 'yyyy-MM-dd hh:mm') }}</template>
          </el-table-column>
          <el-table-column label="标签类型" prop="typeName" show-overflow-tooltip />
          <el-table-column label="操作" prop="name" width="80px">
            <template slot-scope="scope">
              <span class="tool-button" @click="toUpdate(scope.row)">编辑</span>
              <span class="tool-button" @click="toDelete(true, scope.row)">删除</span>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!tagLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-elevator
          show-sizer
          show-total
        />
      </div>
    </div>
    <TagCUDialog ref="cuDialog" @refresh="searchLabel" />
    <ConfirmDialog ref="deleteDialog" :title="'删除标签'" @on-ok="delLabel">
      <div style="width: 270px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px" />
        <span v-if="payload.related">该标签已被使用，是否删除该标签，删除后被使用的标签也将删除</span>
        <span v-else>是否确认删除该标签,删除后将不可恢复</span>
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import TagCUDialog from '@components/common/dialog/TagCUDialog'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import tagApi from '@api/tag'
export default {
  name: 'TagList',
  components: {
    YTIcon,
    TagCUDialog,
    ConfirmDialog
  },
  data() {
    return {
      type: [],
      tagLoading: true,
      payload: {
        related: false,
        tagForms: []
      },
      tagList: [],
      total: 0,
      pageNum: 0,
      pageSize: 10,
      keyword: '',
      typeId: '',
      typeIds: [],
      relationData: []
    }
  },
  created() {
    this.getTypes()
    this.searchLabel()
  },
  methods: {
    getTypes() {
      tagApi.getAllType().then(res => {
        this.type = res.res.map(data => {
          switch (data.typeId) {
            case 3:
              data.typeName = '专业标签'
              break
            case 5:
              data.typeName = '学校标签'
              break
          }
          return data
        })
        // console.log(this.type)
      })
    },
    selectedLabelManagement(selection) {
      this.payload.tagForms = selection.map(item => {
        return {
          tagId: item.tagId,
          typeId: item.typeId
        }
      })
    },
    toDelete(isSingle, data) {
      if (isSingle) {
        this.payload.tagForms = [{ tagId: data.tagId, typeId: data.typeId }]
        this.judgeRelations()
      } else {
        if (this.payload.tagForms.length === 0) {
          this.$message.warning('请先勾选要删除的标签')
        } else {
          this.judgeRelations()
        }
      }
    },
    delLabel() {
      tagApi.deleteTags(this.payload).then(res => {
        if (res.res === true) {
          this.$message.success('删除成功')
          this.payload.tagForms = []
          this.searchLabel()
        }
      })
    },
    judgeRelations() {
      tagApi
        .judgeRelation(this.payload.tagForms)
        .then(res => {
          if (res.code === 0) {
            this.payload.related = res.res
          }
        })
        .finally(() => {
          this.$refs.deleteDialog.open()
        })
    },
    handleClickType(typeId) {
      this.typeId = typeId
      this.reSearchTag()
    },
    searchLabel() {
      let payload = {
        pageNumber: this.pageNum,
        pageSize: this.pageSize
      }
      this.tagLoading = true
      tagApi
        .searchTag(this.keyword, this.typeId, payload)
        .then(res => {
          if (res.res.total > 0 && Math.ceil(res.res.total / this.pageSize) < this.pageNum + 1) {
            this.pageNum = Math.ceil(res.res.total / this.pageSize) - 1
            this.searchLabel()
          } else {
            this.tagList = res.res.data
            this.total = res.res.total
          }
        })
        .finally(() => {
          this.tagLoading = false
        })
    },
    reSearchTag() {
      this.pageNum = 0
      this.searchLabel()
    },
    toCreate() {
      this.$refs.cuDialog.open(true)
    },
    toUpdate(row) {
      this.$refs.cuDialog.open(false)
      this.$nextTick(() => {
        this.$refs.cuDialog.initData(row)
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchLabel()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchLabel()
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  height: 100%;
  text-align: left;
  padding-top: 0;
}
.yt-container {
  height: calc(100% - 48px);
  .yt-content {
    padding-bottom: 7px;
  }
}

.line {
  width: calc(100% + 40px);
  margin-left: -20px;
  height: 1px;
  background: #e2e4e8;
}

.filter-container {
  display: flex;
  align-items: center;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 12px;
  .label {
    margin-left: 20px;
  }
  .item {
    display: inline-block;
    height: 21px;
    line-height: 21px;
    margin: 0 8px;
    padding: 0 15px;
    cursor: pointer;
    &:hover {
      border-radius: 11px;
      background: #448bff;
      color: #ffffff;
    }
  }
  .active-item {
    border-radius: 11px;
    background: #448bff;
    color: #ffffff;
  }
}
</style>
