<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新建标签' : '修改标签'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" label-width="132px" :rules="ruleValidate" class="yt-form yt-dialog-form">
      <el-form-item label="选择标签类型" prop="typeId">
        <el-select v-model="formData.typeId" placeholder="请选择标签类型" prop="typeId">
          <el-option v-for="item in type" :key="item.typeId" :label="item.typeName" :value="item.typeId"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签名称" prop="tagName">
        <el-input placeholder="请输入标签名称" v-model="formData.tagName" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import tagApi from '@api/tag'
export default {
  name: 'TagCUDialog',
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('标签名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      type: [],
      formData: {
        typeId: '',
        tagName: ''
      },
      loading: false,
      id: '',
      ruleValidate: {
        typeId: [{ required: true, message: '请选择标签类型', trigger: 'change' }],
        tagName: [
          { required: true, message: '标签名称不能为空' },
          { max: 10, message: '标签名称长度不能超过10个字符' },
          { min: 1, message: '标签名称长度不小于1个字符' },
          { validator: validateName, trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getTypes()
  },
  methods: {
    initData(row) {
      this.formData.tagName = row.tagName
      this.formData.typeId = row.typeId
      this.id = row.tagId
    },
    open(mode) {
      this.mode = mode
      this.visible = true
    },
    submit() {
      if (this.mode) {
        this.create()
      } else {
        this.update()
      }
    },
    create() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let payload = {
            tagName: this.formData.tagName,
            typeId: this.formData.typeId
          }
          this.loading = true
          tagApi
            .addTag(payload)
            .then(res => {
              if (res.res === true) {
                this.$message.success('添加成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    update() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let payload = {
            tagName: this.formData.tagName,
            typeId: this.formData.typeId,
            tagId: this.id
          }
          this.loading = true
          tagApi
            .updateTag(payload)
            .then(res => {
              if (res.res === true) {
                this.$message.success('修改成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.loading = false
    },
    getTypes() {
      tagApi.getAllType().then(res => {
        this.type = res.res.map(data => {
          switch (data.typeId) {
            case 3:
              data.typeName = '专业标签'
              break
            case 4:
              data.typeName = '学生标签'
              break
            case 5:
              data.typeName = '学校标签'
              break
          }
          return data
        })
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
